import {ChangeEventHandler} from 'react';

type Props = {
  name: string;
  checked: boolean;
  label: string;
  onChange: ChangeEventHandler;
  className?: string;
  accessKey?: string;
}

const ToggleInput = (props: Props) => {
  return (
      <div className={`text-left inline-flex items-center ${props.className}`}>
        <label
            htmlFor={props.name}
            className="flex relative items-center cursor-pointer"
        >
          <input
              type="checkbox"
              id={props.name}
              name={props.name}
              onChange={props.onChange}
              className="sr-only peer"
              checked={props.checked}
              accessKey={props.accessKey}
          />
          <div className="w-11 h-6 bg-gray-600 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-600 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"/>
          <span className="ml-3 text-ellipsis">{props.label}</span>
        </label>
      </div>
  );
};

export default ToggleInput;