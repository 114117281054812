import ToggleInput from './Input/ToggleInput';
import SliderInput from './Input/SliderInput';
import {ChangeEvent} from 'react';
import {usePassword} from '../context/PasswordProvider';
import {sendGaEvent} from '../utils/ga';

const PasswordConfig = () => {
  const {passwordConfig, updateConfig} = usePassword();
  const changeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.type == 'checkbox' ?
        evt.target.checked :
        evt.target.value;

    updateConfig(evt.target.name, value);

    sendGaEvent('password_config', {
      property: evt.target.name,
      value: value,
    });
  };

  return <form>
    <SliderInput
        name="length"
        value={passwordConfig.length}
        label="Password length"
        onChange={changeHandler}
        className="mb-6"
    />
    <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
      <ToggleInput
          name="use_numbers"
          checked={passwordConfig.use_numbers}
          label={'Numbers (123)'}
          accessKey="n"
          onChange={changeHandler}
      />
      <ToggleInput
          name="use_uppercase"
          checked={passwordConfig.use_uppercase}
          label={'Uppercase characters'}
          accessKey="u"
          onChange={changeHandler}
      />
      <ToggleInput
          name="use_lowercase"
          checked={passwordConfig.use_lowercase}
          label={'Lowercase characters'}
          accessKey="l"
          onChange={changeHandler}
      />
      <ToggleInput
          name="use_symbols"
          checked={passwordConfig.use_symbols}
          label={'Symbols (!@#$%^&*())'}
          accessKey="s"
          onChange={changeHandler}
      />
      <ToggleInput
          name="use_other_symbols"
          checked={passwordConfig.use_other_symbols}
          label={'Other symbols (~`[];?,)'}
          accessKey="o"
          onChange={changeHandler}
      />
    </div>
  </form>;
};

export default PasswordConfig;
