const WhySecurePassword = () => <div className="text-left space-y-4 mt-8 max-w-3xl p-2 text-lg">
  <h2 className="font-extrabold text-2xl">Why is secure password important?</h2>
  <p>Online safety and security is of the utmost importance, and having a
    random, secure password is a crucial aspect of protecting your personal
    information and online accounts. Here are a few reasons why:</p>
  <ul className="list-disc list-inside pl-4">
    <li><strong>Random passwords are more difficult for hackers to
      guess</strong>. By using a
      combination of letters, numbers, and special characters, you create a
      password that is less likely to be cracked by automated tools or brute
      force attacks.
    </li>
    <li><strong>A random password is less likely to be found in a dictionary attack</strong>.
      Many hackers use automated tools that can quickly guess commonly used
      words and phrases, so having a random password can help protect your
      account from this type of attack.
    </li>
    <li><strong>Using a different password for each account can prevent a domino effect</strong>.
      In case one account is compromised, the hacker will not be able to access
      your other accounts as well.
    </li>
  </ul>
  <p>Having a random, secure password is one of the most effective ways to
    protect your online accounts and personal information. By following best
    practices for password creation and management, you can help ensure that
    your online presence is safe and secure.</p>
</div>;

export default WhySecurePassword;