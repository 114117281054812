const HowSecurePassword = () => <div
    className="text-left space-y-4 mt-8 max-w-3xl p-2 text-lg">
  <h2 className="font-extrabold text-2xl">How to create strong password?</h2>
  <p>Creating a secure password is essential for keeping your online accounts
    and personal information safe. Here are a few tips for creating a strong
    password:</p>
  <ul className="list-disc list-inside pl-4">
    <li><strong>Use a combination of letters, numbers, and special characters.</strong> Avoid
      using easily guessable information, such as your name or birthdate.
    </li>
    <li>Make your password <strong>at least 12 characters long</strong>. The longer your
      password, the more secure it will be.
    </li>
    <li><strong>Avoid using the same password for multiple accounts</strong>. If one account is
      compromised, all of your accounts will be at risk.
    </li>
    <li><strong>Avoid using common words or phrases</strong>. Hackers often use automated tools
      that can quickly guess common words and phrases.
    </li>
  </ul>
  <p>By following these tips, you can create a secure password that will help
    protect your online accounts and personal information. Remember to keep your
    password safe, and never share it with anyone.</p>
</div>;

export default HowSecurePassword;