import {ChangeEventHandler} from 'react';
import {maxLength, minLength} from '../../types/types';

type Props = {
  name: string;
  value: number;
  label: string;
  onChange: ChangeEventHandler;
  className?: string;
}

const SliderInput = (props: Props) => {
  return <div className={props.className ?? ''}>
    <label>
      {props.label}{` (${props.value})`}
      <div className="flex items-center">
        <input
            className="w-full h-3 bg-black rounded-lg appearance-none cursor-pointer range-lg mr-4"
            type="range"
            id={props.name}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            min={minLength}
            max={maxLength}
        />
        <input
            className="w-14 outline outline-2 px-2 py-1"
            type="number"
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            min={minLength}
            max={maxLength}
        />
      </div>
    </label>
  </div>;
};

export default SliderInput;