import {useState} from 'react';
import RefreshIcon from './icons/RefreshIcon';
import CopyIcon from './icons/CopyIcon';
import {PasswordStatusData} from '../types/types';
import Notice from './Notice';
import {sendGaEvent} from '../utils/ga';

type Props = {
  value: string;
  regenerate: () => void;
  className?: string;
}

enum PasswordStatus {
  generated,
  copied,
  error,
}

const PasswordView = ({value, regenerate, className}: Props) => {
  const [status, setStatus] = useState(PasswordStatus.generated);

  const copyPassword = (value: string) => {
    navigator.clipboard.writeText(value).
        then(() => setStatus(PasswordStatus.copied)).
        catch(() => setStatus(PasswordStatus.error));

    sendGaEvent('password_copy')
  };

  const regeneratePassword = () => {
    regenerate();
    setStatus(PasswordStatus.generated);

    sendGaEvent('password_regenerate')
  };

  const getNoticeData = (): PasswordStatusData => {
    switch (status) {
      case PasswordStatus.generated:
        return {
          message: 'Your new password:',
        };
      case PasswordStatus.copied:
        return {
          message: 'Password successfully copied!',
          className: 'bg-green-500 text-white',
        };
      case PasswordStatus.error:
        return {
          message: 'Something went wrong.',
          className: 'bg-red-500 text-white',
        };
    }
  };

  return <div className={className}>
    <div className="mb-4">
      <Notice noticeData={getNoticeData()}/>
    </div>
    <p className="tracking-widest break-all text-lg md:text-2xl font-mono py-2 px-4 md:px-6 outline outline-dashed outline-2 inline-flex items-center">
      {value}
      <button
          accessKey="g"
          aria-label="Generate new password"
          onClick={regeneratePassword}
      >
        <RefreshIcon className="h-5 ml-6 transition duration-300 ease-in-out hover:rotate-180"/>
      </button>
      <button
          accessKey="c"
          aria-label="Copy password to clipboard"
          onClick={() => copyPassword(value)}
      >
        <CopyIcon className="h-5 ml-4 transition duration-300 ease-in-out hover:scale-125"/>
      </button>
    </p>
    <div className="text-center flex justify-center mt-3">
      <div className="grid gap-4 grid-cols-2">
        <button
            className="flex items-center justify-between border border-2 border-black px-4 py-1.5 group"
            aria-label="Generate new password"
            onClick={regeneratePassword}
        >
          Refresh <RefreshIcon className="h-5 ml-4 transition duration-300 ease-in-out group-hover:rotate-180"/>
        </button>
        <button
            className="flex items-center justify-between bg-black text-white border border-2 border-black px-4 py-1.5 group"
            aria-label="Copy password to clipboard"
            onClick={() => copyPassword(value)}
        >
          Copy <CopyIcon className="h-5 ml-4 transition duration-300 ease-in-out group-hover:scale-125"/>
        </button>
      </div>
    </div>
  </div>;
};

export default PasswordView;