import type {NextPage} from 'next';
import PasswordConfig from '../components/PasswordConfig';
import {useEffect} from 'react';
import {usePassword} from '../context/PasswordProvider';
import PasswordView from '../components/PasswordView';
import MetaHome from '../components/meta/MetaHome';
import IntroDescription from '../components/reassurance/IntroDescription';
import HowSecurePassword from '../components/reassurance/HowSecurePassword';
import WhySecurePassword from '../components/reassurance/WhySecurePassword';

const Home: NextPage = () => {
  const {password, regeneratePassword, passwordConfig} = usePassword();
  useEffect(() => regeneratePassword(passwordConfig), []);

  return (
      <>
        <MetaHome/>
        <main className="flex w-full flex-1 flex-col items-center">
          <IntroDescription/>
          <div className="mt-4 flex w-full justify-center bg-gray-100">
            <div className="text-center my-4 pb-4 max-w-4xl w-full px-4">
              <PasswordView
                  value={password}
                  regenerate={() => regeneratePassword(passwordConfig)}
                  className="min-h-fit"
              />
              <div className="mt-10 pt-5 pb-10 px-5 md:px-10 border border-black border-1">
                <span className="flex justify-center">
                  <span className="-mt-14 p-5 bg-gray-100 text-xl">Options</span>
                </span>
                <PasswordConfig/>
              </div>
            </div>
          </div>
          <HowSecurePassword/>
          <WhySecurePassword/>
        </main>
      </>
  );
};

export default Home;
