import {PasswordStatusData} from '../types/types';

type Props = {
  noticeData: PasswordStatusData
}

const Notice = (props: Props) => {
  return <span className={`${props.noticeData.className} px-4 py-1.5 rounded-full`}>
        {props.noticeData.message}
      </span>

}

export default Notice;