import Head from 'next/head';

const MetaHome = () => {
  return <Head>
    <title>Random Password Generator</title>
    <meta name="description" content="Secure Password Generator. Generate highly secure password online for free."/>

    <meta property="og:title" content="Random Password Generator"/>
    <meta property="og:description" content="Secure Password Generator. Generate highly secure password online for free."/>
    <meta property="og:type" content="website"/>
    <meta property="og:image" content="https://randompassgenerator.com/random-pass-generator-og.jpg"/>
    <meta property="og:url" content="https://randompassgenerator.com"/>
    <meta property="og:locale" content="en" />

    <meta name="theme-color" content="#000000"/>
    <meta name="msapplication-TileColor" content="#ffffff"/>
    <meta name="theme-color" content="#000000"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
    <link rel="manifest" href="/site.webmanifest"/>
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000"/>
  </Head>;
}

export default MetaHome;